@import url(https://fonts.googleapis.com/css2?family=Fira+Code&family=Lato&family=Poppins:wght@600&family=Roboto&family=Source+Code+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@font-face {
  font-family: 'DejaVu Sans Mono';
  src: url(/static/media/dejavu-sans-mono.book.4bb3391b.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Move-X";
  src: local("Move-X"),
    url(/static/media/Move-X_bold.8e5b5961.otf) format("opentype");
}

pre code {
  display: block;
  background: none;
  white-space: pre;
  font-size: 0.9vw;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  max-width: 100%;
  min-width: 100px;
  max-height: 120px;
  padding: 0;
  
  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

pre code::-webkit-scrollbar {
  width: 4px;
}

pre code::-webkit-scrollbar-track {
  background-color: transparent;
}

pre code::-webkit-scrollbar-thumb {
  background-color: #FEFEFE;
  height: 64px;
}

pre code::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  font-family: 'Space Grotesk', sans-serif;
}

button {
  cursor: pointer;
}

.Btn button {
  border-radius: 30px;
  border: 1px solid #FDD649 !important;
  background-color: transparent;
  color: #FDD649;
  transition: 1s ease-in all;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Btn button:hover {
  background-color: #FDD649;
  color: black;
}

.btno {
  display: -webkit-flex;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
}

.btno button {
  width: 170px;
}

.Btn .first {
  background-color: #FDD649 !important;
  color: black;
}

.Btn .first:hover {
  background-color: transparent !important;
  color: #FDD649;
}

@media only screen and (max-width: 500px) {
  .btno {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.landing_wrapper__3V4hY {
  height: calc(100vh - 60px);
}

.landing_feed__inner__1x8U3 {
  font-family: "Space Grotesk";
  display: -webkit-flex;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  /* padding: 24px 11px 24px 32px; */
}

.landing_container__3moDZ {
  position: relative;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 24px;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: calc(100vh - 96px);
}

.landing_jdAnalyzerContainer__2roi1 {
  /* display: flex;
  align-items: center; */
  width: 100%;
}

.landing_innerContainer__2F0Rm {
  display: -webkit-flex;
  display: flex;
  /* align-items: center; */
  /* width: 93%; */
}

.landing_center__25CiI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 10px;
  width: 47%;
  margin: auto;
  text-align: center;
}

.landing_box__1wXuP {
  /* height: 200px; */
  /* width: 270px; */
  width: 384px;
  background-color: #1e1e1e;
  margin: auto;
  border-radius: 15px;
  margin-top: 20px;
  text-align: start;
}

.landing_boxInner__NfX_q {
  /* border-bottom: 1px solid #C3C3C3; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  padding: 16px 20px;
}

.landing_boxInnerLeft__1v2cK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 28px;
  gap: 28px;
}

.landing_boxInner__NfX_q img {
  height: 13px;
  width: 13px;
}

.landing_profileLinkBox__DxlA2 {
  padding: 14px 20px 16px;
}

.landing_profileLinkBox__DxlA2 a {
  color: #fdd649;
  text-decoration: none;
}

.landing_profileLinkBox__DxlA2 a:hover {
  text-decoration: underline;
}

.landing_jobDescInput__2u4NV {
  /* border: 1px solid #121212; */
  background-color: #121212;
  /* position: fixed; */
  /* bottom: 8px; */
  width: 100%;
}

.landing_historyHeader__1hKRI {
  color: #fdd649;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

/* .box p,
.box a {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
} */

/* HERO */
.landing_boxy__3QY-g {
  background-color: #1e1e1e;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 60%;
  border-radius: 25px;
  height: 40px;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px auto;
}

.landing_boxy__3QY-g img {
  height: 15px;
  width: 15px;
}

.landing_cardHeaderLogoWrap__1ZIAk {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  /* margin: 20px 0 0; */
  border: 1px solid #d9d9d9;
}
.landing_cardHeaderLogoWrap__1ZIAk img {
  width: 19px;
}

.landing_jobDesc__CDkL4 {
  display: -webkit-flex;
  display: flex;
  /* align-items: flex-start; */
  border-radius: 20px;
  grid-gap: 18px;
  gap: 18px;
  border: 1px solid #292929;
  position: relative;
  color: #FEFEFE;
  /* margin-top: 28px; */
  padding: 20px;
  height: 139px;
  background-color: #1e1e1e;
}

.landing_jobDesc__CDkL4 p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  /* Clamp to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* max-height: 139px */
}

.landing_jobDesc__CDkL4 h6 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 500;
}

.landing_one__2aJlX {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 20px;
  grid-gap: 18px;
  gap: 18px;
  border: 1px solid transparent;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
      border-box;
  position: relative;
  margin-top: 28px;
  padding: 20px;
}

.landing_white__1016F {
  background-color: white;
  height: 24px;
  width: 4px;
  position: absolute;
  right: 0;
  top: 55px;
}

.landing_one__2aJlX p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.landing_you__3N6Fk {
  background: #473e19;
  height: 30px;
  width: 150px;
  border: 1px solid #fdd649;
  color: #fdd649;
  border-radius: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  margin: 20px 0;
}

.landing_divider__2qVWc {
  background-color: #414141 !important;
  margin-top: 20px !important;
}

.landing_mainDivider__2m6zS {
  background-color: #fdd649;
  height: 1px;
}

.landing_summary__2dxv7,
.landing_cover__3XLe3,
.landing_inter__2GuNT,
.landing_Article__1Ar1B,
.landing_Type__3jX-x {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 18px;
  gap: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
      border-box;
}

.landing_welcome__2pAW3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 18px;
  gap: 18px;
  margin-top: 26px;
  /* margin-bottom: 30px; */
  padding: 20px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
      border-box;
}

.landing_summary__2dxv7 h3,
.landing_cover__3XLe3 h3 {
  padding: 14px 0;
}

.landing_summary__2dxv7 a,
.landing_inter__2GuNT li,
.landing_Article__1Ar1B li {
  color: #fdd649;
}

a {
  color: #fdd649;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.landing_jobAnalysisDisabled__15iO2,
.landing_letterDisabled__17vaA,
.landing_linkedInDMDisabled__1pTdj {
  position: relative;
}

.landing_disableOverlay__E49IH {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 54px;
  gap: 54px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 0 0 20px 20px;
  /* background-color: rgba(100, 100, 100, 0.8);  */
  /* z-index: 1; */
}

/* .disableOverlay:hover { */
.landing_disableOverlay__E49IH {
  cursor: not-allowed;
  /* Adjust the below values (0.5 and 2px) to tweak visibility of the text under */
  background-color: rgba(18, 18, 18, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

/* .disableOverlay:hover .disableOverlayText,
.disableOverlay:hover .disableOverlayBtn {
  display: block;
} */

/* .disableOverlay:hover .disableOverlayText { */
.landing_disableOverlay__E49IH .landing_disableOverlayText__XpaTI {
  color: #121212;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 700;
  cursor: text;
}

/* .disableOverlayBtn,
.disableOverlayText {
  display: none;
} */

.landing_disableOverlayText__XpaTI {
  color: #fefefe !important;
  text-align: center;
  width: 46.33vw;
  padding: 20px;
  border-radius: 20px;
}

.landing_letterTextArea__doXP6 {
  background: transparent;
  width: 100%;
  height: auto;
  color: #fefefe;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-family: "Space Grotesk";
  border: none;
  outline: none;
  margin-bottom: 8px;
}

/* Remove underline from textarea */
.landing_letterTextArea__doXP6 div::before {
  border: none;
}

.landing_MuiInput-underline__8uR0h:hover:not(.landing_Mui-disabled__vKVCH)::before {
  border-bottom: none;
}

.landing_MuiInput-underline__8uR0h:hover:not(.landing_Mui-disabled__vKVCH)::before {
  border-bottom: none;
}

.landing_btn__2CCxL {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.landing_btn__2CCxL button {
  min-width: 70px;
}

.landing_copyBtn__1dRAC {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fdd649;
  cursor: pointer;
  padding: 8px;
  border-radius: 10px;
}

.landing_copyBtn__1dRAC:hover {
  background-color: #fdd649;
  color: #121212;
}

.landing_toggleBtns__1Zdjg {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 296px;
  height: 46px;
}

.landing_coverLetterTitleWrap__14EF3 .landing_toggleBtns__1Zdjg button {
  width: 148px;
  height: 46px;
  padding: 8px 21px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 500;
  border: none !important;
}

.landing_coverLetterTitleWrap__14EF3 .landing_toggleBtns__1Zdjg button:hover {
  background: transparent !important;
  color: #fefefe;
}

.landing_coverLetterTitleWrap__14EF3 .landing_toggleBtns__1Zdjg .landing_btnPry__2JzdD {
  background-color: transparent;
  color: #fefefe !important;
}

.landing_coverLetterTitleWrap__14EF3 .landing_toggleBtns__1Zdjg .landing_btnTert___wlHr {
  background-color: transparent;
  color: #888 !important;
}

.landing_coverLetterTitleWrap__14EF3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* padding: 0 0 20px; */
}

.landing_coverLetterTitle__3LrGJ {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 500;
}

.landing_btnRow__3NA2E svg {
  width: 18px;
  height: 18px;
}

.landing_first__374gm:hover {
  background-color: transparent;
  transition: 1s all ease-in-out;
  border: 1px solid #fdd649;
  color: #fdd649;
}

.landing_header__InH8i {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* margin-bottom: 16px; */
  color: #fefefe;
}

.landing_jd__1BKHo {
  overflow-y: auto;
  /* max-height: 250px; */
  padding-right: 15px;
}

.landing_jd__1BKHo::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.landing_jd__1BKHo::-webkit-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 15px;
}

.landing_jd__1BKHo::-webkit-scrollbar-track {
  background-color: transparent;
}

.landing_jd__1BKHo::-moz-scrollbar {
  width: 4px;
  background-color: transparent;
}

.landing_jd__1BKHo::-moz-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 15px;
}

.landing_jd__1BKHo::-moz-scrollbar-track {
  background-color: transparent;
}

.landing_jd__1BKHo::-ms-scrollbar {
  width: 4px;
  background-color: transparent;
}

.landing_jd__1BKHo::-ms-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 15px;
}

.landing_jd__1BKHo::-ms-scrollbar-track {
  background-color: transparent;
}

.landing_summary_header__1XwqN {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 14px;
}

.landing_summary_list__1E7Xi {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 16px 20px;
}

.landing_summary_list_loading__AhTvI {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 16px 20px;
}

@-webkit-keyframes landing_cursor-blink__3a5wx {
  0% {
    opacity: 0;
  }
}

@keyframes landing_cursor-blink__3a5wx {
  0% {
    opacity: 0;
  }
}

.landing_summary_list_loading__AhTvI li::after {
  content: "";
  width: 15px;
  height: 20px;
  background: #fff;
  display: inline-block;
  -webkit-animation: landing_cursor-blink__3a5wx 1.5s steps(2) infinite;
          animation: landing_cursor-blink__3a5wx 1.5s steps(2) infinite;
}

.landing_topics_header__1NKo1 {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #FEFEFE;
  line-height: 1.5rem;
  font-weight: 500;
}

.landing_topic_card__2X1IN {
  background-color: #292929;
  padding: 16px 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.landing_topic_name__2TGBw {
  margin-bottom: 16px;
  font-size: 18px;
  color: #fefefe;
  line-height: 24px;
  font-weight: 500;
}

.landing_handson__projects_list__1FMV9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  padding-left: 24px;
}

.landing_handson__projects_list__1FMV9 li {
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 500;
  color: #fdd649;
}

.landing_interviewBtnRow__3XXDl {
  display: -webkit-flex;
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
}

.landing_jobSliderWrap__2uzZI {
}

.landing_jobSlider__3nE-1 {
  margin-top: 16px;
}

.landing_jobsSliderHeader__1BP11 {
  display: -webkit-flex;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #fefefe;
  /* width: 59%; */
}

.landing_generatedItemsWrap__3gN6F {
  height: 73.5vh;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.landing_generatedItemsWrap__3gN6F::-webkit-scrollbar {
  /* For Chrome, Safari, and Opera */
  display: none;
}

@media only screen and (min-width: 1501px) {
  .landing_container__3moDZ {
    margin-left: 0;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1500px) {
  /* .feed__inner {
    padding: 16px 16px 16px 76px;
  } */
}

@media only screen and (max-width: 1024px) {
  .landing_container__3moDZ {
    width: 65%;
  }

  .landing_center__25CiI {
    width: 80%;
  }

  /* .feed__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  } */
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
  /* .feed__inner {
    padding: 24px 11px 24px 0px;
  } */
}

@media only screen and (max-width: 1024px) {
  .landing_container__3moDZ {
    width: 90%;
  }

  .landing_asideContent__393Fk {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .landing_innerContainerMobile__2TJw5 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .landing_innerContainerMobile__2TJw5 p {
    /* width: 80%; */
  }
}

@media only screen and (max-width: 700px) {
  .landing_container__3moDZ {
    width: 100%;
    height: calc(90vh - 60px) !important;
    position: "static";
    /* margin: 0; */
  }

  .landing_innerContainer__2F0Rm {
    width: 100%;
  }

  .landing_feed__inner__1x8U3 {
    padding: 8px 16px 0;
  }

  .landing_jobDescInput__2u4NV {
    width: calc(100vw - 70px);
    position: fixed;
    bottom: 76px;
    background: #121212;
    padding-top: 12px;
  }

  .landing_wrapper__3V4hY {
    height: calc(500px - 60px) !important;
  }

  .landing_hide__38dl5 {
    display: none;
  }

  /* .header {
    display: none;
  } */

  .landing_center__25CiI {
    width: 100%;
    margin: 0;
  }

  .landing_btnRow__3NA2E button {
    width: 8px;
  }

  button {
    font-size: 0.875rem !important;
  }

  .landing_cardHeaderLogoWrap__1ZIAk {
    margin-top: 8px;
    width: 24px;
    height: 24px;
    padding: 6px;
  }

  .landing_cardHeaderLogoWrap__1ZIAk img {
    width: 12px;
  }

  .landing_jobsSliderHeader__1BP11 {
    width: 95%;
  }

  .landing_disableOverlay__E49IH:hover .landing_disableOverlayText__XpaTI {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    width: 80%;
  }

  .landing_one__2aJlX,
  .landing_summary__2dxv7,
  .landing_cover__3XLe3,
  .landing_inter__2GuNT,
  .landing_Article__1Ar1B,
  .landing_Type__3jX-x {
    grid-gap: 12px;
    gap: 12px;
  }

  .landing_Type__3jX-x {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 540px) {
  .landing_coverLetterTitleWrap__14EF3 .landing_toggleBtns__1Zdjg {
    width: 35vw;
    min-width: 170px;
    height: 40px;
  }

  .landing_coverLetterTitleWrap__14EF3 .landing_toggleBtns__1Zdjg button {
    width: 80px;
    height: 40px;
    font-size: 14px;
    line-height: 1rem;
    font-weight: 400;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .landing_btnRow__3NA2E .landing_MuiButton-root__iBzgp {
    min-width: 40px;
  }

  .landing_box__1wXuP {
    width: 95%;
    max-width: 384px;
  }
}

@media only screen and (max-width: 390px) {
  .landing_btn__2CCxL {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .landing_btn__2CCxL div {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .landing_jdAnalyzerContainer__2roi1 {
    display: block;
  }
}

/* add css module styles here (optional) */

._1ceqH,
._1ceqH * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif
}

._1ceqH ._2fG9h {
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
}

._1ceqH ._dt3-T {
    /* min-height: 400px; */
    /* background: #212121; */
    background: transparent;
    border-radius: 0 0 3px 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

._1ceqH ._1dpop {
    width: 60px;
    display: -webkit-flex;
    display: flex;
    height: 60px;
    position: fixed;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: .5s ease all;
    background: rgb(245, 0, 87);
    border-radius: 50%;
    bottom: 65px;
    right: 20%;
    color: #fff;
    font-size: 25px;
}

._1ceqH ._3neb0 {
    width: 25px;
    height: 25px;
    display: -webkit-flex;
    display: flex;
}

._1ceqH ._1dpop:hover {
    background-color: #fff;
}

._1ceqH ._3wi1g {
    fill: #fff;
    width: 100%;
    height: 100%;
}

._1ceqH ._1dpop:hover ._3wi1g {
    fill: rgb(245, 0, 87);
}

._1ceqH ._1lB9c {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: #bd9f61;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
}

._1ceqH ._1lB9c ._2N9dq {
    font-weight: 400;
}

._1ceqH ._1lB9c ._3-aC9 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease all;
}

._1ceqH ._1lB9c ._3-aC9:hover {
    background: rgba(123, 118, 106, 0.21);
}

._1ceqH ._3bC73 {
    position: relative;
    -webkit-flex: 1 1;
            flex: 1 1;
}

._1ceqH ._3bC73 ._1dpop {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 20px;
}
/* ._1dpop {
    top: -200px;
    color: #FDD649 !important;
    background-color: transparent !important;
} */
/* ._1dpop svg {
    fill: #FDD649 !important;
} */

._1ceqH ._3bC73 ._1YOWG {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    bottom: 100px;
}

._1ceqH ._1Yplu {
    margin: 20px 30px;
}

._1ceqH ._1Yplu ._1Pz2d {
    border: 0;
    outline: 0;
    padding: 10px 20px;
    border-radius: 20px;
    background: #185fec;
    color: #fff;
    cursor: pointer;
    border: 1px solid #185fec;
    transition: .3s ease all;
}

._1ceqH ._1Yplu ._1Pz2d:hover {
    background: #fff;
    color: #185fec;
}

._1ceqH ._1Yplu ._2gd2_ {
    background: #fff;
    color: #185fec;
    margin-left: 15px;
}

._1ceqH ._1Yplu ._2gd2_[disabled],
._1ceqH ._1Yplu ._37kfa[disabled] {
    cursor: not-allowed;
    color: #d9d9d9;
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}

._1ceqH ._1Yplu ._2gd2_:hover {
    background: #185fec;
    color: #fff;
}

._1ceqH ._f2DT8 {
    text-align: center;
}

._1ceqH ._17RTH {
    min-height: calc(100vh - 128px);
    background: #fff;
}

._1ceqH ._f2DT8 * {
    color: #fff;
    font-size: 60px;
}

._1ceqH ._1YOWG ._eV_dK {
    color: #fff;
}

._1ceqH ._qxztz {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    bottom: 0px;
    padding: 20px 0;
    display: -webkit-flex;
    display: flex;
}

._1ceqH ._qxztz ._2uz65 {
    width: 50px;
    display: -webkit-flex;
    display: flex;
    height: 50px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 50%;
    color: #fff;
    margin-right: 15px;
    font-size: 20px;
}

._1ceqH ._qxztz ._1bSom {
    background: #940505;
    position: relative;
}

._1ceqH ._qxztz ._3nQu5 {
    background: #9c6702;
    position: relative;
}

._1ceqH ._3O0Io {
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 10px 0px 10px 17px;
    border-color: transparent transparent transparent #fff;
    box-sizing: border-box;
    position: absolute;
    left: 56%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
}

._1ceqH ._2ACrw {
    width: 17px;
    height: 17px;
    border-style: double;
    border-width: 0px 0px 0px 15px;
    border-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

._1ceqH ._oEOY- {
    width: 17px;
    height: 17px;
    border: 1.5px solid #fff;
    border-radius: 2px;
}

._1ceqH ._3neb0 {
    width: 30px;
    height: 30px;
    display: -webkit-flex;
    display: flex;
}
.customLoaderWrap {
  width: 172px;
  height: 172px;
  font-size: 10px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.customLoaderWrap .face {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  -webkit-animation: animate023845 3s linear infinite;
          animation: animate023845 3s linear infinite;
}

.customLoaderWrap .face:nth-child(1) {
  width: 172px;
  height: 172px;
  color: #fdd649;
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.2em 0.2em 0em 0em;
  --deg: -45deg;
  -webkit-animation-direction: normal;
          animation-direction: normal;
}

.customLoaderWrap .face:nth-child(2) {
  width: 128px;
  height: 128px;
  color: #02cfbf;
  border-color: currentColor currentColor transparent transparent;
  border-width: 0.2em 0em 0em 0.2em;
  --deg: -135deg;
  animation-direction: reverse;
}

.customLoaderWrap .face .circle {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  -webkit-transform: rotate(var(--deg));
          transform: rotate(var(--deg));
  -webkit-transform-origin: left;
          transform-origin: left;
}

.customLoaderWrap .face .circle::before {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  content: "";
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 50%;
  box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em,
    0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@-webkit-keyframes animate023845 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes animate023845 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.onboarding h4 {
    color: unset;
    text-transform: unset;
}

.onboarding a {
    box-shadow: unset;
    transition: unset;
}

.onboarding p {
    color: unset;
    padding-bottom: 2em;
}
