.onboarding h4 {
    color: unset;
    text-transform: unset;
}

.onboarding a {
    box-shadow: unset;
    transition: unset;
}

.onboarding p {
    color: unset;
    padding-bottom: 2em;
}