@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  font-family: 'Space Grotesk', sans-serif;
}

button {
  cursor: pointer;
}

.Btn button {
  border-radius: 30px;
  border: 1px solid #FDD649 !important;
  background-color: transparent;
  color: #FDD649;
  transition: 1s ease-in all;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Btn button:hover {
  background-color: #FDD649;
  color: black;
}

.btno {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.btno button {
  width: 170px;
}

.Btn .first {
  background-color: #FDD649 !important;
  color: black;
}

.Btn .first:hover {
  background-color: transparent !important;
  color: #FDD649;
}

@media only screen and (max-width: 500px) {
  .btno {
    flex-direction: column;
  }
}