@import url("https://fonts.googleapis.com/css2?family=Fira+Code&family=Lato&family=Poppins:wght@600&family=Roboto&family=Source+Code+Pro:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'DejaVu Sans Mono';
  src: url('/src/fonts/dejavu-sans-mono.book.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Move-X";
  src: local("Move-X"),
    url(./fonts/Move-X_bold.otf) format("opentype");
}

pre code {
  display: block;
  background: none;
  white-space: pre;
  font-size: 0.9vw;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  max-width: 100%;
  min-width: 100px;
  max-height: 120px;
  padding: 0;
  
  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

pre code::-webkit-scrollbar {
  width: 4px;
}

pre code::-webkit-scrollbar-track {
  background-color: transparent;
}

pre code::-webkit-scrollbar-thumb {
  background-color: #FEFEFE;
  height: 64px;
}

pre code::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
