.wrapper {
  height: calc(100vh - 60px);
}

.feed__inner {
  font-family: "Space Grotesk";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  /* padding: 24px 11px 24px 32px; */
}

.container {
  position: relative;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 96px);
}

.jdAnalyzerContainer {
  /* display: flex;
  align-items: center; */
  width: 100%;
}

.innerContainer {
  display: flex;
  /* align-items: center; */
  /* width: 93%; */
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  width: 47%;
  margin: auto;
  text-align: center;
}

.box {
  /* height: 200px; */
  /* width: 270px; */
  width: 384px;
  background-color: #1e1e1e;
  margin: auto;
  border-radius: 15px;
  margin-top: 20px;
  text-align: start;
}

.boxInner {
  /* border-bottom: 1px solid #C3C3C3; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px 20px;
}

.boxInnerLeft {
  display: flex;
  align-items: center;
  gap: 28px;
}

.boxInner img {
  height: 13px;
  width: 13px;
}

.profileLinkBox {
  padding: 14px 20px 16px;
}

.profileLinkBox a {
  color: #fdd649;
  text-decoration: none;
}

.profileLinkBox a:hover {
  text-decoration: underline;
}

.jobDescInput {
  /* border: 1px solid #121212; */
  background-color: #121212;
  /* position: fixed; */
  /* bottom: 8px; */
  width: 100%;
}

.historyHeader {
  color: #fdd649;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

/* .box p,
.box a {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
} */

/* HERO */
.boxy {
  background-color: #1e1e1e;
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  border-radius: 25px;
  height: 40px;
  align-items: center;
  margin: 20px auto;
}

.boxy img {
  height: 15px;
  width: 15px;
}

.cardHeaderLogoWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  /* margin: 20px 0 0; */
  border: 1px solid #d9d9d9;
}
.cardHeaderLogoWrap img {
  width: 19px;
}

.jobDesc {
  display: flex;
  /* align-items: flex-start; */
  border-radius: 20px;
  gap: 18px;
  border: 1px solid #292929;
  position: relative;
  color: #FEFEFE;
  /* margin-top: 28px; */
  padding: 20px;
  height: 139px;
  background-color: #1e1e1e;
}

.jobDesc p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  /* Clamp to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* max-height: 139px */
}

.jobDesc h6 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 500;
}

.one {
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  gap: 18px;
  border: 1px solid transparent;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
      border-box;
  position: relative;
  margin-top: 28px;
  padding: 20px;
}

.white {
  background-color: white;
  height: 24px;
  width: 4px;
  position: absolute;
  right: 0;
  top: 55px;
}

.one p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.you {
  background: #473e19;
  height: 30px;
  width: 150px;
  border: 1px solid #fdd649;
  color: #fdd649;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px 0;
}

.divider {
  background-color: #414141 !important;
  margin-top: 20px !important;
}

.mainDivider {
  background-color: #fdd649;
  height: 1px;
}

.summary,
.cover,
.inter,
.Article,
.Type {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
      border-box;
}

.welcome {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  margin-top: 26px;
  /* margin-bottom: 30px; */
  padding: 20px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
      border-box;
}

.summary h3,
.cover h3 {
  padding: 14px 0;
}

.summary a,
.inter li,
.Article li {
  color: #fdd649;
}

a {
  color: #fdd649;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.jobAnalysisDisabled,
.letterDisabled,
.linkedInDMDisabled {
  position: relative;
}

.disableOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 54px;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 0 0 20px 20px;
  /* background-color: rgba(100, 100, 100, 0.8);  */
  /* z-index: 1; */
}

/* .disableOverlay:hover { */
.disableOverlay {
  cursor: not-allowed;
  /* Adjust the below values (0.5 and 2px) to tweak visibility of the text under */
  background-color: rgba(18, 18, 18, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

/* .disableOverlay:hover .disableOverlayText,
.disableOverlay:hover .disableOverlayBtn {
  display: block;
} */

/* .disableOverlay:hover .disableOverlayText { */
.disableOverlay .disableOverlayText {
  color: #121212;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 700;
  cursor: text;
}

/* .disableOverlayBtn,
.disableOverlayText {
  display: none;
} */

.disableOverlayText {
  color: #fefefe !important;
  text-align: center;
  width: 46.33vw;
  padding: 20px;
  border-radius: 20px;
}

.letterTextArea {
  background: transparent;
  width: 100%;
  height: auto;
  color: #fefefe;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-family: "Space Grotesk";
  border: none;
  outline: none;
  margin-bottom: 8px;
}

/* Remove underline from textarea */
.letterTextArea div::before {
  border: none;
}

.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: none;
}

.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: none;
}

.btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.btn button {
  min-width: 70px;
}

.copyBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdd649;
  cursor: pointer;
  padding: 8px;
  border-radius: 10px;
}

.copyBtn:hover {
  background-color: #fdd649;
  color: #121212;
}

.toggleBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 296px;
  height: 46px;
}

.coverLetterTitleWrap .toggleBtns button {
  width: 148px;
  height: 46px;
  padding: 8px 21px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 500;
  border: none !important;
}

.coverLetterTitleWrap .toggleBtns button:hover {
  background: transparent !important;
  color: #fefefe;
}

.coverLetterTitleWrap .toggleBtns .btnPry {
  background-color: transparent;
  color: #fefefe !important;
}

.coverLetterTitleWrap .toggleBtns .btnTert {
  background-color: transparent;
  color: #888 !important;
}

.coverLetterTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 0 20px; */
}

.coverLetterTitle {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 500;
}

.btnRow svg {
  width: 18px;
  height: 18px;
}

.first:hover {
  background-color: transparent;
  transition: 1s all ease-in-out;
  border: 1px solid #fdd649;
  color: #fdd649;
}

.header {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* margin-bottom: 16px; */
  color: #fefefe;
}

.jd {
  overflow-y: auto;
  /* max-height: 250px; */
  padding-right: 15px;
}

.jd::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.jd::-webkit-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 15px;
}

.jd::-webkit-scrollbar-track {
  background-color: transparent;
}

.jd::-moz-scrollbar {
  width: 4px;
  background-color: transparent;
}

.jd::-moz-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 15px;
}

.jd::-moz-scrollbar-track {
  background-color: transparent;
}

.jd::-ms-scrollbar {
  width: 4px;
  background-color: transparent;
}

.jd::-ms-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 15px;
}

.jd::-ms-scrollbar-track {
  background-color: transparent;
}

.summary_header {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 14px;
}

.summary_list {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 16px 20px;
}

.summary_list_loading {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 16px 20px;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.summary_list_loading li::after {
  content: "";
  width: 15px;
  height: 20px;
  background: #fff;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}

.topics_header {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #FEFEFE;
  line-height: 1.5rem;
  font-weight: 500;
}

.topic_card {
  background-color: #292929;
  padding: 16px 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.topic_name {
  margin-bottom: 16px;
  font-size: 18px;
  color: #fefefe;
  line-height: 24px;
  font-weight: 500;
}

.handson__projects_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 24px;
}

.handson__projects_list li {
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 500;
  color: #fdd649;
}

.interviewBtnRow {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-bottom: 20px;
}

.jobSliderWrap {
}

.jobSlider {
  margin-top: 16px;
}

.jobsSliderHeader {
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #fefefe;
  /* width: 59%; */
}

.generatedItemsWrap {
  height: 73.5vh;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.generatedItemsWrap::-webkit-scrollbar {
  /* For Chrome, Safari, and Opera */
  display: none;
}

@media only screen and (min-width: 1501px) {
  .container {
    margin-left: 0;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1500px) {
  /* .feed__inner {
    padding: 16px 16px 16px 76px;
  } */
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 65%;
  }

  .center {
    width: 80%;
  }

  /* .feed__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  } */
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
  /* .feed__inner {
    padding: 24px 11px 24px 0px;
  } */
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 90%;
  }

  .asideContent {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .innerContainerMobile {
    display: flex;
    justify-content: space-between;
  }

  .innerContainerMobile p {
    /* width: 80%; */
  }
}

@media only screen and (max-width: 700px) {
  .container {
    width: 100%;
    height: calc(90vh - 60px) !important;
    position: "static";
    /* margin: 0; */
  }

  .innerContainer {
    width: 100%;
  }

  .feed__inner {
    padding: 8px 16px 0;
  }

  .jobDescInput {
    width: calc(100vw - 70px);
    position: fixed;
    bottom: 76px;
    background: #121212;
    padding-top: 12px;
  }

  .wrapper {
    height: calc(500px - 60px) !important;
  }

  .hide {
    display: none;
  }

  /* .header {
    display: none;
  } */

  .center {
    width: 100%;
    margin: 0;
  }

  .btnRow button {
    width: 8px;
  }

  button {
    font-size: 0.875rem !important;
  }

  .cardHeaderLogoWrap {
    margin-top: 8px;
    width: 24px;
    height: 24px;
    padding: 6px;
  }

  .cardHeaderLogoWrap img {
    width: 12px;
  }

  .jobsSliderHeader {
    width: 95%;
  }

  .disableOverlay:hover .disableOverlayText {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    width: 80%;
  }

  .one,
  .summary,
  .cover,
  .inter,
  .Article,
  .Type {
    gap: 12px;
  }

  .Type {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 540px) {
  .coverLetterTitleWrap .toggleBtns {
    width: 35vw;
    min-width: 170px;
    height: 40px;
  }

  .coverLetterTitleWrap .toggleBtns button {
    width: 80px;
    height: 40px;
    font-size: 14px;
    line-height: 1rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btnRow .MuiButton-root {
    min-width: 40px;
  }

  .box {
    width: 95%;
    max-width: 384px;
  }
}

@media only screen and (max-width: 390px) {
  .btn {
    display: flex;
    align-items: flex-start;
  }

  .btn div {
    flex-wrap: wrap;
  }

  .jdAnalyzerContainer {
    display: block;
  }
}
